<template>
  <div class="legend-container">
    <div class="title">图例</div>
    <div class="legend">
      <div class="item" v-for="item in list" :key="item.title" @click="select(item.title)">
        <template v-if="selected.indexOf(item.title) === -1">
          <img :src="item.icon" />
          <span>{{ item.title }}</span>
        </template>
        <template v-else>
          <img :src="item.selectedIcon" />
          <span class="active">{{ item.title }}</span>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      list: [
        {
          icon: "/icon/flow.png",
          selectedIcon: "/icon/flow-active.png",
          title: "流量计",
        },
        {
          icon: "/icon/liquid.png",
          selectedIcon: "/icon/liquid-active.png",
          title: "液位计",
        },
        {
          icon: "/icon/rain.png",
          selectedIcon: "/icon/rain-active.png",
          title: "雨量计",
        },
        {
          icon: "/icon/monitor.png",
          selectedIcon: "/icon/monitor-active.png",
          title: "视频监控",
        },
        {
          icon: "/icon/water-quality.png",
          selectedIcon: "/icon/water-quality-active.png",
          title: "水质监测箱",
        },
        {
          icon: "/icon/intercepting-well.png",
          selectedIcon: "/icon/intercepting-well-active.png",
          title: "智慧截流井",
        },
        {
          icon: "/icon/sewage-well.png",
          selectedIcon: "/icon/sewage-well-active.png",
          title: "污水检查井",
        },
        {
          icon: "/icon/rain-well.png",
          selectedIcon: "/icon/rain-well-active.png",
          title: "雨水检查井",
        },
        {
          icon: "/icon/sewage-pipe.png",
          selectedIcon: "/icon/sewage-pipe-active.png",
          title: "污水管网",
        },
        {
          icon: "/icon/rain-pipe.png",
          selectedIcon: "/icon/rain-pipe-active.png",
          title: "雨水管网",
        },
        {
          icon: "/icon/rain-outlet.png",
          selectedIcon: "/icon/rain-outlet-active.png",
          title: "雨水排口",
        },
      ],
    };
  },

  methods: {
    select(title) {
      const index = this.selected.indexOf(title);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(title);
      }

      this.$emit("change", this.selected);
    },
  },
};
</script>
<style lang="less" scoped>
.legend-container {
  position: fixed;
  z-index: 1;
  bottom: 4vh;
  left: 4vw;
  background-color: #000;
  color: #999;
  font-size: 1.6vh;

  .title {
    text-align: center;
    font-size: 1.25em;
    background-color: #709180;
    color: #fff;
    padding: 5px 0;
  }

  .legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 2vh;
    padding: 2vh 2vh;

    .item {
      cursor: pointer;
      display: flex;
      align-items: center;
      text-align: center;
    }

    img {
      height: 2.4vh;
      margin-right: 8px;
    }
  }

  .active {
    //color: var(--theme-color);
  }
}
</style>
