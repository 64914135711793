<template>
  <div class="top-tool-bar">
    <div class="item" v-for="item in list" :key="item.title" @click="select(item.title)">
      <img :src="item.activeIcon" v-if=" view.indexOf( item.title) > -1" />
      <img :src="item.defaultIcon" v-else />
      <span :class="view.indexOf(item.title) > -1 ? 'active' : ''">{{ item.title }}</span>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      list: [
        {
          title: "管网",
          activeIcon: require("@/assets/assets/topBar/pipe-active.png"),
          defaultIcon: require("@/assets/assets/topBar/pipe-default.png"),
        },
        {
          title: "检查井",
          activeIcon: require("@/assets/assets/topBar/inspectwell-active.png"),
          defaultIcon: require("@/assets/assets/topBar/inspectwell-default.png"),
        },
        {
          title: "排口",
          activeIcon: require("@/assets/assets/topBar/outlet-active.png"),
          defaultIcon: require("@/assets/assets/topBar/outlet-default.png"),
        },
        {
          title: "截流井",
          activeIcon: require("@/assets/assets/topBar/cutowell-active.png"),
          defaultIcon: require("@/assets/assets/topBar/cutowell-default.png"),
        },
        {
          title: "设备",
          activeIcon: require("@/assets/assets/topBar/equip-active.png"),
          defaultIcon: require("@/assets/assets/topBar/equip-default.png"),
        },
      ],
    };
  },
  computed: {
    ...mapState("assets", ["view"]),
  },
  methods: {
    ...mapMutations("assets", ["setView"]),
    select(title) {
      this.setView(title);
    },
  },
};
</script>
<style lang="less" scoped>
.top-tool-bar {
  position: fixed;
  left: 4vw;
  top: 10vh;
  background-color: #000;
  padding: 2vh 2vh;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  font-size: 1.6vh;
  gap: 1vw;
  z-index: 1;
  .item {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;

    img {
      height: 3vh;
    }
  }

  .active {
    color: var(--theme-color) !important;
  }
}
</style>
