var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticStyle:{"cursor":"pointer","margin-bottom":"16px"},on:{"click":_vm.back}},[_c('a-icon',{staticStyle:{"margin-right":"2px"},attrs:{"type":"left"}}),_c('span',[_vm._v("返回")])],1),_c('a-form',{attrs:{"label-col":{ span: 8 },"wrapper-col":{ span: 14 },"colon":false,"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"所属分类"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'category',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]),expression:"[\n              'category',\n              {\n                rules: [{ required: true, message: '请选择' }],\n              },\n            ]"}],staticStyle:{"width":"100%"},attrs:{"disabled":_vm.view === '修改排口'}},_vm._l((_vm.pipeTypes),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
              'code',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]),expression:"[\n              'code',\n              {\n                rules: [{ required: true, message: '请输入' }],\n              },\n            ]"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"经纬度"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['lnglat',  {
                rules: [{ required: true, message: '请输入' }],
              }]),expression:"['lnglat',  {\n                rules: [{ required: true, message: '请输入' }],\n              }]"}],attrs:{"disabled":true}},[_c('span',{staticStyle:{"cursor":"pointer"},attrs:{"slot":"suffix"},on:{"click":_vm.pasteLnglat},slot:"suffix"},[_c('img',{staticClass:"location",attrs:{"src":require("@/assets/assets/location.png")}})])])],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"地面高程(m)"}},[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['elevation']),expression:"['elevation']"}],staticStyle:{"width":"100%"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"所在位置"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['location']),expression:"['location']"}]})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"附属物"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['accessary']),expression:"['accessary']"}]})],1)],1),_c('a-col',{attrs:{"lg":24,"md":24,"sm":24}},[_c('a-form-item',{attrs:{"label":"关联管道","label-col":{ span: 4 },"wrapper-col":{ span: 19 }}},[_c('PipeTable',{attrs:{"category":_vm.form.getFieldValue('category'),"list":_vm.pipeList,"disabled":_vm.view === '修改排口'},on:{"change":payload => _vm.pipeList = payload}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":_vm.back}},[_vm._v("取消")]),_c('a-button',{attrs:{"type":"primary","html-type":"submit","loading":_vm.saveLoading}},[_vm._v("保存")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }