import { PointLayer } from "@antv/l7";

export default {

  watch: {
    sewageWellList: {
      handler() {
        this.getSewageWell();
      },
      deep: true,
    }
  },

  methods: {
     getSewageWell() {
      if (this.selected.indexOf('污水检查井') === -1) return;
      
      this.removeSewageWell();

          const zoom = this.map?.getZoom() || 3;

          if (zoom < 16.7) return;

          this.sewagewellLayer = new PointLayer({
            zIndex: 100,
          }).source(this.sewageWellList, {
              parser: {
                type: "json",
                x: "x",
                y: "y",
              },
            })
            .shape("sewageWell")
            .size(zoom * 0.55)

          this.sewagewellLayer.on("contextmenu", this.onFacilityRightClick);
          this.scene?.addLayer(this.sewagewellLayer);
    },

    removeSewageWell() {
        if (this.sewagewellLayer) {
                this.scene?.removeLayer(this.sewagewellLayer);
                this.sewagewellLayer = null;
            }
    },
  },
};
