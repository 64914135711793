import { PointLayer } from "@antv/l7";

export default {

    watch: {
    rainOutletList: {
      handler() {
        this.getOutlet();
      },
      deep: true,
    }
  },

  methods: {
     getOutlet() {
        if (this.selected.indexOf('雨水排口') === -1) return;

          this.removeOutlet();

        const zoom = this.map?.getZoom() || 3;

        this.outletLayer = new PointLayer({
          zIndex: 100,
        })

          .source(this.rainOutletList, {
            parser: {
              type: "json",
              x: "x",
              y: "y",
            },
          })
          .shape("outlet")
          .size(zoom * 0.55)

        this.outletLayer.on("contextmenu", this.onFacilityRightClick); // 直接用设备右键事件
        this.scene?.addLayer(this.outletLayer);
    },
        removeOutlet() {
       if (this.outletLayer) {
        this.scene?.removeLayer(this.outletLayer);
        this.outletLayer = null;
      }
    },


  },
};
