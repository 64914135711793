<template>
  <div>
    <div class="selector" v-show="show">
      <div class="header">
        <div class="title">{{ view }}</div>
        <div class="extra">
          <a-icon type="minus" v-if="show" @click="show = false" />
        </div>
      </div>

      <div class="body">
        <Pipe v-if="view === '管网'" />
        <PipeEdit v-if="view === '新增管网' || view === '修改管网'" />
        <InspectWell v-if="view === '检查井'" />
        <InspectWellEdit v-if="view === '新增检查井' || view === '修改检查井'" />
        <Outlet v-if="view === '排口'" />
        <OutletEdit v-if="view === '新增排口' || view === '修改排口'" />
        <InterceptingWell v-if="view === '截流井'" />
        <InterceptingWellEdit v-if="view === '新增截流井' || view === '修改截流井'" />
        <Equip v-if="view === '设备'" />
        <EquipEdit v-if="view === '新增设备' || view === '修改设备'" />
      </div>
    </div>
    <div class="plus" v-show="!show" @click="show = true">
      <a-icon type="plus" />
    </div>
  </div>
</template>

<script>
import Pipe from "../pipe/index.vue";
import PipeEdit from "../pipe/edit.vue";
import InspectWell from "../inspectWell/index.vue";
import InspectWellEdit from "../inspectWell/edit.vue";
import Outlet from "../outlet/index.vue";
import OutletEdit from "../outlet/edit.vue";
import InterceptingWell from "../interceptingWell/index.vue";
import InterceptingWellEdit from "../interceptingWell/edit.vue";
import Equip from "../equip/index.vue";
import EquipEdit from "../equip/edit.vue";
import { mapState } from "vuex";

export default {
  components: {
    Pipe,
    PipeEdit,
    InspectWell,
    InspectWellEdit,
    Outlet,
    OutletEdit,
    InterceptingWell,
    InterceptingWellEdit,
    Equip,
    EquipEdit,
  },

  data() {
    return {
      show: true,
    };
  },

  computed: {
    ...mapState("assets", ["view"]),
  },
};
</script>

<style lang="less" scoped>
.selector {
  position: absolute;
  top: 10vh;
  right: 4vw;
  z-index: 2;
  background-color: var(--bg-color);

  width: 50vw;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 10px;

  .header {
    background-color: var(--theme-color);
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1vw;
    line-height: 4vh;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .body {
    padding: 2vh 1vw 0;
  }
}

.plus {
  position: absolute;
  top: 10vh;
  right: 1vw;
  z-index: 2;
  background-color: var(--theme-color);
  color: #fff;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  border-radius: 50%;
  height: 24px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
</style>
