<template>
  <div>
    <div style="cursor: pointer;margin-bottom: 16px" @click="back">
      <a-icon type="left" style="margin-right: 2px" />
      <span>返回</span>
    </div>

    <a-form
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 14 }"
      :colon="false"
      :form="form"
      @submit="handleSubmit"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="所属分类">
            <a-select
              :disabled="view === '修改检查井'"
              style="width: 100%"
              v-decorator="[
                'category',
                {
                  rules: [{ required: true, message: '请选择' }],
                },
              ]"
              @change="onCategoryChange"
            >
              <a-select-option
                v-for="item in pipeTypes"
                :key="item.value"
                :value="item.value"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="井类型">
            <a-select
              :disabled="view === '修改检查井'"
              style="width: 100%"
              v-decorator="[
                'type',
                {
                  rules: [{ required: true, message: '请选择' }],
                },
              ]"
            >
              <a-select-option
                v-for="item in wellTypes"
                :key="item.value"
                :value="item.value"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="编号">
            <a-input
              v-decorator="[
                'code',
                {
                  rules: [{ required: true, message: '请输入' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="经纬度">
            <a-input
              :disabled="true"
              v-decorator="['lnglat',  {
                  rules: [{ required: true, message: '请输入' }],
                }]"
            >
              <span slot="suffix" @click="pasteLnglat" style="cursor: pointer">
                <img src="@/assets/assets/location.png" class="location" />
              </span>
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="位置">
            <a-input v-decorator="['location']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="井盖直径(m)">
            <a-input-number style="width: 100%" v-decorator="['diameterCover']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="井室直径(m)">
            <a-input-number style="width: 100%" v-decorator="['diameterRoom']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="地面高程(m)">
            <a-input-number style="width: 100%" v-decorator="['elevation']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="井盖材质">
            <a-input v-decorator="['materialCover']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="检查井材质">
            <a-input v-decorator="['materialWell']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="井盖形状">
            <a-input v-decorator="['shapeCover']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="井深">
            <a-input v-decorator="['depth']" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="关联管道" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
            <PipeTable
              :category="form.getFieldValue('category')"
              :list="pipeList"
              :disabled="view === '修改检查井'"
              @change="payload => pipeList = payload"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="back">取消</a-button>
          <a-button type="primary" html-type="submit" :loading="saveLoading">保存</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>
<script>
import store from "../mixins/store";
import { mapGetters } from "vuex";
import PipeTable from "../components/pipe-table.vue";

import { add, edit, fetchDetail } from "@/api/assets/inspectWell.js";

export default {
  mixins: [store],

  components: {
    PipeTable,
  },

  data() {
    return {
      wellTypes: [],

      detail: {},
      form: this.$form.createForm(this),

      pipeList: [],
      saveLoading: false,
    };
  },

  watch: {
    selectedWell: {
      handler() {
        if (this.view === "修改检查井" && this.selectedWell._edit) {
          this.getDetail();
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    pipeTypes() {
      return this.findDataDict("pipeType");
    },
    rainWellTypes() {
      return this.findDataDict("rainWellType");
    },
    sewageWellTypes() {
      return this.findDataDict("sewageWellType");
    },
  },

  mounted() {
    if (this.view === "修改检查井" && this.selectedWell._edit) {
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      this.wellTypes = [];
      this.detail = {};
      this.form.resetFields();
      this.pipeList = [];
      const hide = this.$message.loading("加载中...", 0);
      fetchDetail({
        id: this.selectedWell.id,
        category: this.selectedWell.category,
      })
        .then((res) => {
          if (!res.id) return;
          this.detail = res;

          this.form.setFieldsValue({
            category: res.category,
            type: res.type,
            code: res.code,
            location: res.location,
            diameterCover: res.diameterCover,
            diameterRoom: res.diameterRoom,
            elevation: res.elevation,
            materialCover: res.materialCover,
            material: res.material,
            shapeCover: res.shapeCover,
            materialWell: res.materialWell,
            depth: res.depth,
            lnglat: res.x + "," + res.y,
          });

          if (res.category === "sewage") {
            this.wellTypes = this.sewageWellTypes;
          } else {
            this.wellTypes = this.rainWellTypes;
          }

          const pipeList = [];
          if (Array.isArray(res.pipesFrom)) {
            res.pipesFrom.forEach((item) => {
              pipeList.push({
                ...item,
                type: item.category,
                location: "from",
              });
            });
          }

          if (Array.isArray(res.pipesTo)) {
            res.pipesTo.forEach((item) => {
              pipeList.push({
                ...item,
                type: item.category,
                location: "to",
              });
            });
          }

          this.pipeList = pipeList;
        })
        .finally(() => {
          hide();
        });
    },

    pasteLnglat() {
      const { lng, lat } = this.selectedLnglat;
      if (!lng) {
        this.$message.error("请点击地图选择经纬度");
        return;
      }
      this.form.setFieldsValue({
        lnglat: lng + "," + lat,
      });
    },

    onCategoryChange(value) {
      this.form.setFieldsValue({
        type: undefined,
      });

      if (value === "sewage") {
        this.wellTypes = this.sewageWellTypes;
      } else {
        this.wellTypes = this.rainWellTypes;
      }
    },

    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          const arr = values.lnglat.split(",");
          const x = parseFloat(arr[0]);
          const y = parseFloat(arr[1]);

          let flag = false;
          if (this.pipeList.length > 0) {
            this.pipeList.forEach((item) => {
              if (!item.location) {
                flag = true;
              }
            });
          }

          if (flag) {
            this.$message.error("请选择管线位置");
            return;
          }

          this.saveLoading = true;

          let params = {
            id: this.detail.id,
            ...values,
            x,
            y,
            pipePolicies: this.pipeList,
          };

          let task = add;
          if (this.detail.id) {
            task = edit;
          }
          task(params)
            .then(() => {
              if (values.category === "rain") {
                this.getRainWellList();
              } else {
                this.getSewageWellList();
              }
              this.back();
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },

    back() {
      this.wellTypes = [];
      this.detail = {};
      this.form.resetFields();
      this.pipeList = [];

      this.setSelectedLnglat({});
      this.setSelectedWell({});
      this.setSelectedPipe({});
      this.setView("检查井");
    },
  },
};
</script>

<style lang="less" scoped>
.location {
  display: block;
  height: auto;
  width: 12px;
}

.center {
  padding-bottom: 24px;
}
</style>
