import { mapState, mapActions, mapMutations} from 'vuex'

export default {


      computed: {
    ...mapState("assets", [
        'view',
      "facilityList",
            "sewagePipeList",
      "rainPipeList",
      "rainOutletList",
      "sewageWellList",
      "rainWellList",
      "selectedLnglat",
      "selectedWell",
      "selectedPipe",
    ]),
  },

  methods: {
    ...mapMutations("assets", [
        'setView',
      "setSelectedLnglat",
      "setSelectedWell",
      "setSelectedPipe",
    ]),
    ...mapActions("assets", [
      "getFacilityList",
      "getSewagePipeList",
      "getRainPipeList",
      "getRainOutletList",
      "getSewageWellList",
      "getRainWellList",
    ]),

  },
}