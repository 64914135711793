<template>
  <div>
    <div style="cursor: pointer;margin-bottom: 16px" @click="back">
      <a-icon type="left" style="margin-right: 2px" />
      <span>返回</span>
    </div>

    <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" :colon="false" :form="form" @submit="handleSubmit">
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="设备分类">
            <a-select style="width: 100%" v-decorator="[
              'type',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]">
              <a-select-option v-for="item in deviceType" :key="item.value" :value="item.value">{{ item.name
                }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="编号">
            <a-input v-decorator="[
              'code',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="名称">
            <a-input v-decorator="['name', {
              rules: [{ required: true, message: '请输入' }],
            }]" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="唯一标识">
            <a-input v-decorator="['sn', {
              rules: [{ required: true, message: '请输入' }],
            }]" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="经纬度">
            <a-input v-decorator="['lnglat', {
              rules: [{ required: true, message: '请输入' }],
            }]">
              <span slot="suffix" @click="pasteLnglat" style="cursor: pointer">
                <img src="@/assets/assets/location.png" class="location" />
              </span>
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="依附井-编号">
            <a-input :disabled="true" v-decorator="['well']">
              <span slot="suffix" @click="pasteWell" style="cursor: pointer">
                <img src="@/assets/assets/location.png" class="location" />
              </span>
            </a-input>
          </a-form-item>
        </a-col>


        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="设备责任人">
            <a-input v-decorator="['charger']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="设备规格">
            <a-input v-decorator="['specification']" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="位置" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
            <a-input v-decorator="['location']" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="设备备注" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
            <a-input v-decorator="['description']" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="设备状态" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
            <a-select style="width: 100%" v-decorator="['status', {
              rules: [{ required: true, message: '请选择' }],
            }]">
              <a-select-option v-for="item in status" :key="item.value" :value="item.value">{{ item.name
                }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="back">取消</a-button>
          <a-button type="primary" html-type="submit" :loading="saveLoading">保存</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { fetchDetail, add, edit } from "@/api/assets/equip.js";

import store from "../mixins/store";

export default {
  mixins: [store],

  data() {
    return {
      detail: {},
      form: this.$form.createForm(this),
      well: {},
      saveLoading: false,
    };
  },

  watch: {
    selectedWell: {
      handler() {
        if (this.view === "修改设备" && this.selectedWell._edit) {
          this.getDetail();
        }
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    deviceType() {
      return this.findDataDict("deviceType").filter(
        (item) => item.value !== "cutoffWell"
      );
    },
    status() {
      return this.findDataDict("status").map((item) => {
        return {
          value: parseInt(item.value),
          name: item.name,
        };
      });
    },
  },

  mounted() {
    if (this.view === "修改设备" && this.selectedWell._edit) {
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      this.detail = {};
      this.form.resetFields();
      this.well = {};

      const hide = this.$message.loading("加载中...", 0);
      fetchDetail({ id: this.selectedWell.id })
        .then((res) => {

          if (!res) return;

          this.detail = res;
          /*
          * 这段代码试图从facilityList中寻找上级，将上级的信息添加到this.well中
          * 但是试了下，返回都是0。
          * 所以将其注释，改从deviceWellVo中取值
          * 
          const obj = this.facilityList.find(
            // devicePid 是 device_Parent_id的意思，相当于设备关联的上级外键，
            // equip页面中，用来绑定到检查井
            (item) => item.id === res.devicePid

          );
          console.
          if (obj) {
            this.well = obj;
          }
          */
          const bind_well_detail = res.deviceWellVo ? res.deviceWellVo : null;
          this.form.setFieldsValue({
            code: res.code,
            name: res.name,
            type: res.type,
            sn: res.sn,
            lnglat: res.x + "," + res.y,

            location: res.location,
            charger: res.charger,
            description: res.description,
            specification: res.specification,
            status: res.status,
            well: bind_well_detail ? bind_well_detail.code : "未定义",
          });
        })
        .finally(() => {
          hide();
        });
    },

    pasteLnglat() {
      const { lng, lat } = this.selectedLnglat;
      if (!lng) {
        this.$message.error("请点击地图选择经纬度");
        return;
      }
      this.form.setFieldsValue({
        lnglat: lng + "," + lat,
      });
    },

    pasteWell() {
      // 这个函数是在确定选择的依附井后，将依附井的数据信息复制到 selectdWell中，再复制到this.well中
      // 同时修改表单中的显示内容
      const { code, id, x, y } = this.selectedWell;
      console.log("CHOOSE ", this.selectedWell)
      if (!id) {
        this.$message.error("请选择依附井");
        return;
      }
      // 
      this.well = { ...this.selectedWell };
      // 修改表单中的显示内容，其中well是依附井字段的编号(形如WS123)，lnglat是经纬度字段
      this.form.setFieldsValue({
        //well: name,
        well: code,
        lnglat: x + "," + y,
      });
    },

    handleSubmit(e) {
      // 处理表单提交事件
      e.preventDefault();

      this.form.validateFields((err, values) => {
        // 如果校验无错误，开始提交
        if (!err) {
          // 转换经纬度
          const arr = values.lnglat.split(",");
          const x = parseFloat(arr[0]);
          const y = parseFloat(arr[1]);

          this.saveLoading = true;

          // 
          const params = {
            id: this.detail.id,
            ...values,
            lnglat: undefined,
            devicePid: this.well ? this.well.id : undefined, // 对于液位计和流量计，将well的id，绑定到设备parentID
            x,
            y,
          };

          if (this.well) {
            params.devicePid = this.well ? this.well.id : undefined,
              params.category = this.well ? this.well.category : undefined
          }

          let task = add;

          if (this.detail.id) {
            task = edit;
          }
          // 提交参数
          task(params)
            .then(() => {
              this.getFacilityList();
              this.back();
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },

    back() {
      this.detail = {};
      this.form.resetFields();
      this.well = {};

      this.setSelectedLnglat({});
      this.setSelectedWell({});

      this.setView("设备");
    },
  },
};
</script>
<style lang="less" scoped>
.location {
  display: block;
  height: auto;
  width: 12px;
}

.center {
  padding-bottom: 24px;
}
</style>
