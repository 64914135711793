<template>
  <div>
    <div style="cursor: pointer;margin-bottom: 16px" @click="back">
      <a-icon type="left" style="margin-right: 2px" />
      <span>返回</span>
    </div>


    <a-form :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }" :colon="false" :form="form" @submit="handleSubmit">
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="分类">
            <a-select :disabled="view === '修改截流井'" style="width: 100%" v-decorator="[
              'subType',
              {
                rules: [{ required: true, message: '请选择' }],
              },
            ]">
              <a-select-option v-for="item in wellTypes" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="编号">
            <a-input v-decorator="[
              'code',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]" />
          </a-form-item>
        </a-col>


        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="名称">
            <a-input v-decorator="[
              'name',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]" />
          </a-form-item>
        </a-col>


        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="经纬度">
            <a-input :disabled="true" v-decorator="['lnglat', {
              rules: [{ required: true, message: '请输入' }],
            }]">
              <span slot="suffix" @click="pasteLnglat" style="cursor: pointer">
                <img src="@/assets/assets/location.png" class="location" />
              </span>
            </a-input>
          </a-form-item>
        </a-col>


        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="唯一标识">
            <a-input style="width: 100%" v-decorator="['sn', {
              rules: [{ required: true, message: '请输入' }],
            },]" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="位置">
            <a-input v-decorator="[
              'location',
              {
                rules: [{ required: true, message: '请输入' }],
              },
            ]" />
          </a-form-item>
        </a-col>


        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="设备状态">
            <a-select style="width: 100%" v-decorator="['status', {
              rules: [{ required: true, message: '请选择' }],
            }]">
              <a-select-option v-for="item in status" :key="item.value" :value="item.value">{{ item.name
              }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>


        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="关联管道" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
            <PipeTable :list="pipeList" :disabled="view === '修改截流井'" @change="payload => pipeList = payload" />
          </a-form-item>
        </a-col>

      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="back">取消</a-button>
          <a-button type="primary" html-type="submit" :loading="saveLoading">保存</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>
<script>
import PipeTable from "../components/pipe-table";
import { mapGetters, } from "vuex";
import { add, edit, fetchDetail } from "@/api/assets/interceptWell.js";
import store from "../mixins/store";

export default {
  mixins: [store],
  components: {
    PipeTable,
  },
  data() {
    return {
      form: this.$form.createForm(this),
      detail: {},
      pipeList: [],
      saveLoading: false,
    };
  },

  watch: {
    selectedWell: {
      handler() {
        if (this.view === "修改截流井" && this.selectedWell._edit) {
          this.getDetail();
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    wellTypes() {
      return this.findDataDict("cutoffWellType");
    },
    status() {
      return this.findDataDict("status").map((item) => {
        return {
          value: parseInt(item.value),
          name: item.name,
        };
      });
    },
  },

  mounted() {
    if (this.view === "修改截流井" && this.selectedWell._edit) {
      this.getDetail();
    }
  },

  methods: {
    pasteLnglat() {
      const { lng, lat } = this.selectedLnglat;
      if (!lng) {
        this.$message.error("请点击地图选择经纬度");
        return;
      }
      this.form.setFieldsValue({
        lnglat: lng + "," + lat,
      });
    },


    getDetail() {
      this.detail = {};
      this.form.resetFields();
      this.pipeList = [];
      fetchDetail({ id: this.selectedWell.id, category: this.selectedWell.category }).then((res) => {
        if (!res.id) return;

        this.detail = res;

        this.form.setFieldsValue({
          code: res.code,
          subType: res.subType,
          name: res.name,
          location: res.location,
          sn: res.sn,
          status: res.status,
          lnglat: res.x + ',' + res.y,
        });

        // 井与管道关联
        if (!res.pipesFrom) {
          res.pipesFrom = [];
        }
        if (!res.pipesTo) {
          res.pipesTo = [];
        }

        this.pipeList = [
          ...res.pipesFrom.map((item) => {
            return {
              ...item,
              location: "from",
              code: item.code,
              category: item.category,
            };
          }),
          ...res.pipesTo.map((item) => {
            return {
              ...item,
              location: "to",
              code: item.code,
              category: item.category,
            };
          }),
        ];
      });
    },



    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {

          const arr = values.lnglat.split(",");
          const x = parseFloat(arr[0]);
          const y = parseFloat(arr[1]);

          let flag = false;
          if (this.pipeList.length > 0) {
            this.pipeList.forEach((item) => {
              if (!item.location) {
                flag = true;
              }
            });
          }

          if (flag) {
            this.$message.error("请选择管线位置");
            return;
          }

          this.saveLoading = true;

          let params = {
            id: this.detail.id,
            ...values,
            type: "cutoffWell",
            x,
            y,
            pipePolicies: this.pipeList,
          };

          let task = add;
          if (this.detail.id) {
            task = edit;
          }


          task(params).then(() => {
            this.getFacilityList();
            this.back();
          }).finally(() => {
            this.saveLoading = false;
          })
        }
      });
    },

    back() {
      this.detail = {};
      this.form.resetFields();
      this.pipeList = [];

      this.setSelectedLnglat({});
      this.setSelectedWell({});
      this.setSelectedPipe({});
      this.setView("截流井");
    },

  },

};
</script>

<style lang="less" scoped>
.location {
  display: block;
  height: auto;
  width: 12px;
}

.center {
  padding-bottom: 24px;
}
</style>
