<template>
  <div>
    <a-button type="primary" @click="addRow" :disabled="disabled">添加</a-button>

    <div style="padding-top: 8px"></div>

    <a-table bordered :data-source="list" :pagination="false">
      <a-table-column title="编号" align="center">
        <template slot-scope="text">
          <span>{{ text.id }}</span>
          <!-- <a href="#" @click.prevent="select(text)">{{ text.id }}</a> -->
        </template>
      </a-table-column>

      <a-table-column title="分类" align="center">
        <template slot-scope="text">
          <span>{{ text.type === 'sewage' ? '污水' : '雨水' }}</span>
        </template>
      </a-table-column>

      <a-table-column title="位置" align="center">
        <template slot-scope="text,row,index">
          <a-select size="small" :value="text.location" style="width: 120px" @change="(val) => {
              onChange(val, index);
            }
            " :disabled="disabled">
            <a-select-option v-for="item in pipeTypeList" :key="item.value" :value="item.value">{{ item.name
            }}</a-select-option>
          </a-select>
        </template>
      </a-table-column>

      <a-table-column title="操作" align="center" v-if="!disabled">
        <template slot-scope="text,row,index">
          <a href="#" class="danger" @click.prevent="remove(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>
<script>
import store from "../mixins/store";

export default {
  mixins: [store],

  props: {
    category: {
      type: String,
      default: "",
    },
    list: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      pipeTypeList: [
        {
          value: "from",
          name: "管道起点",
        },
        {
          value: "to",
          name: "管道终点",
        },
        {
          value: "in",
          name: "管道中间",
        },
      ],
    };
  },

  methods: {
    addRow() {
      if (this.category && this.selectedPipe.category !== this.category) {
        this.$message.error("请选择同类型管线");
        return;
      }

      if (this.list.find((item) => item.id === this.selectedPipe.id)) {
        this.$message.error("请勿重复添加");
        return;
      }

      this.list.push({
        ...this.selectedPipe,
      });
    },

    onChange(val, index) {
      let target = this.list[index];
      this.list.splice(index, 1, {
        ...target,
        location: val,
      });
      this.$emit("change", this.list);
    },
    remove(index) {
      this.list.splice(index, 1);
      this.$emit("change", this.list);
    },

    // select(row) {
    //   this.setSelectedPipe({
    //     id: row.id,
    //     type: row.category,
    //     startLnglat: [row.xfrom, row.yfrom],
    //     endLnglat: [row.xto, row.yto],
    //     _change: true,
    //   });
    // },
  },
};
</script>