<template>
  <div>
    <div style="cursor: pointer;margin-bottom: 16px" @click="back">
      <a-icon type="left" style="margin-right: 2px" />
      <span>返回</span>
    </div>

    <a-form
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 14 }"
      :colon="false"
      :form="form"
      @submit="handleSubmit"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="所属分类">
            <a-select
              style="width: 100%"
              v-decorator="[
                'category',
                {
                  rules: [{ required: true, message: '请选择' }],
                },
              ]"
              :disabled="view === '修改排口'"
            >
              <a-select-option
                v-for="item in pipeTypes"
                :key="item.value"
                :value="item.value"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="编号">
            <a-input
              v-decorator="[
                'code',
                {
                  rules: [{ required: true, message: '请输入' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="经纬度">
            <a-input
              :disabled="true"
              v-decorator="['lnglat',  {
                  rules: [{ required: true, message: '请输入' }],
                }]"
            >
              <span slot="suffix" @click="pasteLnglat" style="cursor: pointer">
                <img src="@/assets/assets/location.png" class="location" />
              </span>
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="地面高程(m)">
            <a-input-number style="width: 100%" v-decorator="['elevation']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="所在位置">
            <a-input v-decorator="['location']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="附属物">
            <a-input v-decorator="['accessary']" />
          </a-form-item>
        </a-col>

        <a-col :lg="24" :md="24" :sm="24">
          <a-form-item label="关联管道" :label-col="{ span: 4 }" :wrapper-col="{ span: 19 }">
            <PipeTable
              :category="form.getFieldValue('category')"
              :list="pipeList"
              :disabled="view === '修改排口'"
              @change="payload => pipeList = payload"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="back">取消</a-button>
          <a-button type="primary" html-type="submit" :loading="saveLoading">保存</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>
<script>
import store from "../mixins/store";
import PipeTable from "../components/pipe-table.vue";

import { mapGetters } from "vuex";

import { add, edit, fetchDetail } from "@/api/assets/outlet.js";

export default {
  mixins: [store],
  components: {
    PipeTable,
  },
  data() {
    return {
      detail: {},
      form: this.$form.createForm(this),
      pipeList: [],
      saveLoading: false,
    };
  },

  watch: {
    selectedWell: {
      handler() {
        if (this.view === "修改排口" && this.selectedWell._edit) {
          this.getDetail();
        }
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    pipeTypes() {
      return this.findDataDict("pipeType");
    },
  },

  mounted() {
    if (this.view === "修改排口" && this.selectedWell._edit) {
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      this.detail = {};
      this.form.resetFields();
      this.pipeList = [];
      const hide = this.$message.loading("加载中...", 0);

      fetchDetail({
        id: this.selectedWell.id,
        category: this.selectedWell.category,
      })
        .then((res) => {
          if (!res.id) return;

          this.detail = res;

          this.form.setFieldsValue({
            code: res.code,
            category: res.category,
            location: res.location,
            elevation: res.elevation,
            accessary: res.accessary,
            lnglat: res.x + "," + res.y,
          });

          if (!res.pipesFrom) {
            res.pipesFrom = [];
          }
          if (!res.pipesTo) {
            res.pipesTo = [];
          }

          this.pipeList = [
            ...res.pipesFrom.map((item) => {
              return {
                ...item,
                location: "from",
              };
            }),
            ...res.pipesTo.map((item) => {
              return {
                ...item,
                location: "to",
              };
            }),
          ];
        })
        .finally(() => {
          hide();
        });
    },

    pasteLnglat() {
      const { lng, lat } = this.selectedLnglat;
      if (!lng) {
        this.$message.error("请点击地图选择经纬度");
        return;
      }
      this.form.setFieldsValue({
        lnglat: lng + "," + lat,
      });
    },

    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields((err, values) => {
        if (!err) {
          let flag = false;
          if (this.pipeList.length > 0) {
            this.pipeList.forEach((item) => {
              if (!item.location) {
                flag = true;
              }
            });
          }

          if (flag) {
            this.$message.error("请选择管线位置");
            return;
          }

          this.saveLoading = true;

          const arr = values.lnglat.split(",");
          const x = parseFloat(arr[0]);
          const y = parseFloat(arr[1]);

          let params = {
            id: this.detail.id,
            type: "drain",
            ...values,
            x,
            y,
            pipePolicies: this.pipeList,
          };

          let task = add;

          if (this.detail.id) {
            task = edit;
          }

          task(params).then(() => {
            this.getRainOutletList();
            this.back();
          });
        }
      });
    },

    back() {
      this.detail = {};
      this.form.resetFields();
      this.pipeList = [];

      this.setSelectedLnglat({});
      this.setSelectedWell({});
      this.setSelectedPipe({});
      this.setView("排口");
    },
  },
};
</script>


<style lang="less" scoped>
.location {
  display: block;
  height: auto;
  width: 12px;
}

.center {
  padding-bottom: 24px;
}
</style>
