<template >
  <div>
    <Record v-if="record.id" :record="record" @cancel="record = {}" />
    <div v-else>
      <a-row>
        <a-col :span="16">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-select style="width: 10vw" placeholder="所属管网" v-model="form.category">
                <a-select-option
                  v-for="item in typeList"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item>
              <a-input
                v-model="form.code"
                placeholder="编号"
                style="width: 10vw"
                @keyup.enter.native="query"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button type="primary" @click="query">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>

        <a-col :span="8">
          <div class="right" style="margin-top: 4px">
            <a-space>
              <ImportExcel category="well" @refresh="reset" />
              <a-button @click="download">下载模版</a-button>
              <a-button type="primary" @click="setView('新增检查井')">新增</a-button>
            </a-space>
          </div>
        </a-col>
      </a-row>

      <div style="margin-bottom: 16px"></div>

      <a-table
        bordered
        @change="onChange"
        :data-source="list"
        :scroll="{ x: '80vw' }"
        :loading="loading"
        rowKey="id"
        :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }"
      >
        <a-table-column title="编号" align="center">
          <template slot-scope="text">
            <a href="#" @click.prevent="select(text)">{{ text.code }}</a>
          </template>
        </a-table-column>

        <a-table-column title="所属管网" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="pipeType" :dictValue="text.category" iconType="text" />
          </template>
        </a-table-column>

        <a-table-column title="地面高程(m)" data-index="elevation" align="center"></a-table-column>
        <a-table-column title="井盖材质" data-index="materialCover" align="center"></a-table-column>
        <a-table-column title="检查井材质" data-index="materialWell" align="center"></a-table-column>
        <a-table-column title="井盖形状" data-index="shapeCover" align="center"></a-table-column>
        <a-table-column title="井类型" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="wellType" :dictValue="text.type" />
          </template>
        </a-table-column>

        <a-table-column title="井室直径" data-index="diameterRoom" align="center"></a-table-column>
        <a-table-column title="井盖直径(mm)" data-index="diameterCover" align="center"></a-table-column>
        <a-table-column title="操作" width="160px" fixed="right" align="center">
          <template slot-scope="text">
            <a-space>
              <a @click="
                setRecord(text)
              ">维保记录</a>
              <a @click.stop="edit(text)">编辑</a>
              <a class="danger" @click.prevent="deleteText(text)">删除</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
      <div v-if="list.length === 0" style="padding-bottom: 16px"></div>
    </div>
  </div>
</template>
<script>
import store from "../mixins/store";
import ImportExcel from "../components/ImportExcel";
import Record from "./record.vue";

import { fetchList, del } from "@/api/assets/inspectWell";
import { mapGetters } from "vuex";

export default {
  mixins: [store],

  components: {
    ImportExcel,
    Record,
  },
  data() {
    return {
      form: {},
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,

      record: {},
    };
  },
  mounted() {
    this.getList();
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    typeList() {
      return this.findDataDict("pipeType");
    },
  },
  methods: {
    download() {
      window.open(
        "https://qiniu.njszy.com/iot/production/template/%E6%A3%80%E6%9F%A5%E4%BA%95-%E6%A8%A1%E6%9D%BF.xls"
      );
    },

    getList() {
      this.loading = true;
      let { current, pageSize, form } = this;

      fetchList({
        pageNum: current,
        pageSize,
        ...form,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
    query() {
      this.current = 1;
      this.getList();
    },

    edit(row) {
      this.select({
        ...row,
        _edit: true,
      });
      this.setView("修改检查井");
    },

    select(row) {
      this.setSelectedWell({
        ...row,
        _change: true,
      });
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除这个检查井吗？",
        onOk() {
          del({
            id: text.id,
            category: text.category,
          }).then(async () => {
            that.getList();
            if (text.category === "rain") {
              that.getRainWellList();
            } else if (text.category === "sewage") {
              that.getSewageWellList();
            }
          });
        },
      });
    },

    setRecord(text) {
      this.record = text;
    },
  },
};
</script>