import { PointLayer } from "@antv/l7";
export default {

  watch: {
    facilityList: {
      handler() {
        this.getCuto();
        this.getLiquid();
        this.getFlow();
        this.getRainGauge();
        this.getVideo();
        this.getWaterQuality();
      },
      deep: true,
    }
  },

  methods: {
    onFacilityRightClick(e) {
      console.log('右键点击',e)
      const { clientX , clientY} = e.target;
      //console.log("feature:",e.feature)
      // 默认Facility都是井？
      this.setSelectedWell(e.feature)

      this.setSelectedPipe({})
      this.controlX = clientX;
      this.controlY = clientY;
    },

     getCuto() {
      if (this.selected.indexOf('智慧截流井') === -1) return;

          this.removeCuto();

        const zoom = this.map?.getZoom() || 3;
        const data = this.facilityList.filter(item => item.type === 'cutoffWell');

        this.cutoLayer = new PointLayer({
          zIndex: 100,
        })
          .source(data, {
            parser: {
              type: "json",
              x: "x",
              y: "y",
            },
          })
          .shape("cutoffwell")
          .size(zoom * 0.55)

        this.cutoLayer.on("contextmenu", this.onFacilityRightClick);
        this.scene?.addLayer(this.cutoLayer);
    },

    removeCuto() {
       if (this.cutoLayer) {
        this.scene?.removeLayer(this.cutoLayer);
        this.cutoLayer = null;
      }
    },

    getLiquid() {
          if (this.selected.indexOf('液位计') === -1) return;

          this.removeLiquid();

        const zoom = this.map?.getZoom() || 3;
          const data = this.facilityList.filter(item => item.type === 'liquidLevel');

        this.liquidLayer = new PointLayer({
          zIndex: 100,
        })
          .source(data, {
            parser: {
              type: "json",
              x: "x",
              y: "y",
            },
          })
          .shape("liquidLevel")
          .size(zoom * 0.55)

        this.liquidLayer.on("contextmenu", this.onFacilityRightClick);
        this.scene?.addLayer(this.liquidLayer);
    },

    removeLiquid() {
       if (this.liquidLayer) {
        this.scene?.removeLayer(this.liquidLayer);
        this.liquidLayer = null;
      }
    },

    getFlow() {
            if (this.selected.indexOf('流量计') === -1) return;

          this.removeFlow();

        const zoom = this.map?.getZoom() || 3;
        const data = this.facilityList.filter(item => item.type === 'flowMeter');

        this.flowLayer = new PointLayer({
          zIndex: 100,
        }).source(data, {
            parser: {
              type: "json",
              x: "x",
              y: "y",
            },
          })
          .shape("flowMeter")
          .size(zoom * 0.55)
 

        this.flowLayer.on("contextmenu", this.onFacilityRightClick);
        this.scene?.addLayer(this.flowLayer);
    },

    removeFlow() {
       if (this.flowLayer) {
        this.scene?.removeLayer(this.flowLayer);
        this.flowLayer = null;
      }
    },

    
    getVideo() {
        if (this.selected.indexOf('视频监控') === -1) return;

          this.removeVideo();

        const zoom = this.map?.getZoom() || 3;
        const data = this.facilityList.filter(item => item.type === 'video');

        this.videoLayer = new PointLayer({
          zIndex: 100,
        }).source(data, {
            parser: {
              type: "json",
              x: "x",
              y: "y",
            },
          })
          .shape("video")
          .size(zoom * 0.55)

        this.videoLayer.on("contextmenu", this.onFacilityRightClick);
        this.scene?.addLayer(this.videoLayer);
    },

    removeVideo() {
       if (this.videoLayer) {
        this.scene?.removeLayer(this.videoLayer);
        this.videoLayer = null;
      }
    },

    getWaterQuality() {
          if (this.selected.indexOf('水质监测箱') === -1) return;

          this.removeWaterQuaility();

        const zoom = this.map?.getZoom() || 3;
        const data = this.facilityList.filter(item => item.type === 'waterquality');

        this.waterQualityLayer = new PointLayer({
          zIndex: 100,
        })
          .source(data, {
            parser: {
              type: "json",
              x: "x",
              y: "y",
            },
          })
          .shape("waterQuality")
          .size(zoom * 0.55)

        this.waterQualityLayer.on("contextmenu", this.onFacilityRightClick);
        this.scene?.addLayer(this.waterQualityLayer);
    },

    removeWaterQuaility() {
       if (this.waterQualityLayer) {
        this.scene?.removeLayer(this.waterQualityLayer);
        this.waterQualityLayer = null;
      }
    },

    getRainGauge() {
        if (this.selected.indexOf('雨量计') === -1) return;

          this.removeRainGauge();

        const zoom = this.map?.getZoom() || 3;
        const data = this.facilityList.filter(item =>  item.type && item.type.toUpperCase() === 'rainFall'.toUpperCase());

        this.rainGaugeLayer = new PointLayer({
          zIndex: 100,
        }).source(data, {
            parser: {
              type: "json",
              x: "x",
              y: "y",
            },
          })
          .shape("rainFall")
          .size(zoom * 0.55)

        this.rainGaugeLayer.on("contextmenu", this.onFacilityRightClick);
        this.scene?.addLayer(this.rainGaugeLayer);
    },


    removeRainGauge() {
       if (this.rainGaugeLayer) {
        this.scene?.removeLayer(this.rainGaugeLayer);
        this.rainGaugeLayer = null;
      }
    },

                    setFacilityActive(info) {
                        this.closeFacilityActive();

                        if (!info.x) return;

                 const zoom = this.map?.getZoom() || 3;

                this.facilityActiveLayer = new PointLayer({
                    zIndex: 200,
                })
                    .source([info], {
                        parser: {
                            type: 'json',
                            x: 'x',
                            y: 'y'
                        }
                    })
                    .shape('simple')
                    .color('#ff0000')
                    .size(zoom * 0.6)

                this.facilityActiveLayer.on('click', this.closeFacilityActive)
                this.scene?.addLayer(this.facilityActiveLayer);
                
                if (info._change) {
                  this.scene?.setCenter([info.x, info.y], {
                      padding: {
                        right: 400,
                      },
                  });
                }
        },

        closeFacilityActive() {
            if (this.facilityActiveLayer) {
                this.scene?.removeLayer(this.facilityActiveLayer);
                this.facilityActiveLayer = null;
            }
        }

  },
};
