<template>
  <div>
    <Record v-if="record.id" :record="record" @cancel="record = {}" />
    <div v-else>
      <a-row>
        <a-col :span="20">
          <a-form-model layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.code" placeholder="编号" style="width: 10vw" @keyup.enter.native="query"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button type="primary" @click="query">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>

        <a-col :span="4">
          <div class="right" style="margin-top: 4px">
            <a-button type="primary" @click="setView('新增截流井')">新增</a-button>
          </div>
        </a-col>
      </a-row>

      <div style="padding-bottom: 16px"></div>

      <a-table bordered @change="onChange" :data-source="list" :loading="loading" rowKey="id" :pagination="{
        total,
        current,
        pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }">
        <a-table-column title="编号" align="center">
          <template slot-scope="text">
            <a href="#" @click.prevent="select(text)">{{ text.code }}</a>
          </template>
        </a-table-column>

        <a-table-column title="名称" data-index="name" align="center"></a-table-column>

        <a-table-column title="分类" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="cutoffWellType" :dictValue="text.subType" />
          </template>
        </a-table-column>

        <a-table-column title="唯一标识" data-index="sn" align="center"></a-table-column>
        <a-table-column title="位置" data-index="location" align="center"></a-table-column>

        <a-table-column title="状态" width="60px" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="status" :dictValue="String(text.status)" iconType="text" />
          </template>
        </a-table-column>

        <a-table-column title="操作" width="160px" align="center">
          <template slot-scope="text">
            <a-space>
              <a @click="
                setRecord(text)
                ">维保记录</a>
              <a @click.stop="edit(text)">编辑</a>
              <a class="danger" @click.prevent="deleteText(text)">删除</a>
            </a-space>
          </template>
        </a-table-column>
      </a-table>
      <div v-if="list.length === 0" style="padding-bottom: 16px"></div>
    </div>
  </div>
</template>
<script>
import { fetchList, del } from "@/api/assets/interceptWell";
import Record from "./record.vue";
import store from '../mixins/store'

export default {
  mixins: [store],
  components: {
    Record,
  },

  data() {
    return {
      form: {},
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,

      record: {},
    };
  },
  mounted() {
    this.getList();
  },

  methods: {

    getList() {
      this.loading = true;
      let { current, pageSize, form } = this;

      fetchList({
        pageNum: current,
        pageSize,
        ...form,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
    query() {
      this.current = 1;
      this.getList();
    },

    edit(row) {
      this.select({
        ...row,
        _edit: true,
      });
      this.setView('修改截流井')
    },

    select(row) {
      this.setSelectedWell({
        ...row,
        _change: true,
      });
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除这个截流井吗？",
        onOk() {
          del({
            id: text.id,
          }).then(() => {
            that.getList();
            that.getFacilityList();
          });
        },
      });
    },

    setRecord(text) {
      this.record = text;
    },
  },
};
</script>
