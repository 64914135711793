import request from "../request";

export function importFile(data) {
  return request({
    url: "/model-analysis/pipe/import",
    method: "post",
    data,
  });
}

// 维保记录
export function fetchRecordList(data) {
  return request({
    url: "/model-analysis/inspectTask/queryDeviceTaskList",
    method: "post",
    data,
  });
}
