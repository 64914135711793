<template>
  <div>
    <div id="map"></div>

    <TopToolBar />
    <List />
    <Legend :selected="selected" @change="(payload) => (selected = payload)" />

    <div class="control-wrapper" v-if="controlX > 0" @click="onCancel">
      <div
        id="control"
        :style="{ left: controlX + 'px', top: controlY + 'px' }"
      >
        <div @click.stop="onSelect" class="select">选择</div>
        <div @click.stop="onEdit" class="edit">编辑</div>
        <div @click.stop="onDelete" class="delete">删除</div>
      </div>
    </div>
  </div>
</template>
<script>
import TopToolBar from "./components/top-tool-bar";
import Legend from "./components/Legend";
import List from "./components/list.vue";

import store from "./mixins/store";

import sewagePipe from "./mixins/sewage-pipe";
import rainPipe from "./mixins/rain-pipe";
import rainWell from "./mixins/rain-well";
import sewageWell from "./mixins/sewage-well";
import outlet from "./mixins/outlet";
import facility from "./mixins/facility";

import { Scene, Marker } from "@antv/l7";

import { GaodeMapV2 } from "@antv/l7-maps";

import { del as delInterceptingWell } from "@/api/assets/interceptWell";
import { del as delEquip } from "@/api/assets/equip";
import { del as delPipe } from "@/api/assets/pipe";
import { del as delOutlet } from "@/api/assets/outlet";
import { del as delWell } from "@/api/assets/inspectWell";

export default {
  components: { TopToolBar, Legend, List },
  mixins: [store, sewagePipe, rainPipe, facility, rainWell, sewageWell, outlet],

  data() {
    return {
      selected: [
        "流量计",
        "液位计",
        "雨量计",
        "视频监控",
        "水质监测箱",
        "智慧截流井",
        "污水检查井",
        "雨水检查井",
        "污水管网",
        "雨水管网",
        "雨水排口",
      ],

      controlX: -200,
      controlY: -200,
    };
  },

  watch: {
    selected: {
      handler() {
        if (this.selected.indexOf("流量计") > -1) {
          this.getFlow();
        } else {
          this.removeFlow();
        }

        if (this.selected.indexOf("液位计") > -1) {
          this.getLiquid();
        } else {
          this.removeLiquid();
        }

        if (this.selected.indexOf("雨量计") > -1) {
          this.getRainGauge();
        } else {
          this.removeRainGauge();
        }

        if (this.selected.indexOf("视频监控") > -1) {
          this.getVideo();
        } else {
          this.removeVideo();
        }

        if (this.selected.indexOf("水质监测箱") > -1) {
          this.getWaterQuality();
        } else {
          this.removeWaterQuaility();
        }

        if (this.selected.indexOf("智慧截流井") > -1) {
          this.getCuto();
        } else {
          this.removeCuto();
        }

        if (this.selected.indexOf("污水管网") > -1) {
          this.getSewagePipe();
        } else {
          this.removeSewagePipe();
        }

        if (this.selected.indexOf("雨水管网") > -1) {
          this.getrainpipe();
        } else {
          this.removerainpipe();
        }

        if (this.selected.indexOf("雨水排口") > -1) {
          this.getOutlet();
        } else {
          this.removeOutlet();
        }

        if (this.selected.indexOf("污水检查井") > -1) {
          this.getSewageWell();
        } else {
          this.removeSewageWell();
        }

        if (this.selected.indexOf("雨水检查井") > -1) {
          this.getrainwell();
        } else {
          this.removerainwell();
        }
      },
      deep: true,
    },

    selectedLnglat: {
      handler() {
        if (this.selectedMarker) {
          this.scene?.removeAllMarkers();
          this.selectedMarker = null;
        }

        const { lng, lat } = this.selectedLnglat;
        if (lng) {
          this.selectedMarker = new Marker({}).setLnglat({ lng, lat });
          this.scene?.addMarker(this.selectedMarker);
        }
      },
      deep: true,
    },

    selectedWell: {
      handler() {
        this.setFacilityActive(this.selectedWell);
      },
      deep: true,
    },

    selectedPipe: {
      handler() {
        this.setPipeActive(this.selectedPipe);
      },
      deep: true,
    },
  },

  mounted() {
    this.map = this.initMap();

    this.scene = new Scene({
      id: "map",
      map: new GaodeMapV2({
        mapInstance: this.map,
      }),
    });

    this.scene?.addImage("video", "/icon/monitor-active.png");
    this.scene?.addImage("liquidLevel", "/icon/liquid-active.png");
    this.scene?.addImage("flowMeter", "/icon/flow-active.png");
    this.scene?.addImage("cutoffwell", "/icon/intercepting-well-active.png");
    this.scene?.addImage("waterQuality", "/icon/water-quality-active.png");
    this.scene?.addImage("rainFall", "/icon/rain-active.png");

    this.scene?.addImage("sewageWell", "/icon/sewage-well-active.png");
    this.scene?.addImage("rainwell", "/icon/rain-well-active.png");

    this.scene?.addImage("outlet", "/icon/rain-outlet-active.png");

    this.getFacilityList();

    this.getSewagePipeList();
    this.getRainPipeList();

    this.getRainOutletList();

    this.getSewageWellList();
    this.getRainWellList();

    this.scene?.on("click", this.onMapClick);
  },

  methods: {
    onCancel() {
      this.setSelectedWell({});
      this.setSelectedPipe({});
      this.controlX = -200;
      this.controlY = -200;
    },
    onSelect() {
      if (this.selectedWell.id) {
        this.$message.info("已选择" + this.selectedWell.name);
      } else if (this.selectedPipe.id) {
        let name =
          this.selectedPipe.type === "sewage" ? "污水管线" : "雨水管线";
        this.$message.info("已选择" + name);
      }
      this.controlX = -200;
      this.controlY = -200;
    },
    onEdit() {
      if (this.selectedWell.id) {
        this.setSelectedWell({
          ...this.selectedWell,
          _edit: true,
        });
        if (this.selectedWell.type === "cutoffWell") {
          this.setView("修改截流井");
        } else if (this.selectedWell.type === "drain") {
          this.setView("修改排口");
        } else if (
          this.selectedWell.name === "污水检查井" ||
          this.selectedWell.name === "雨水检查井"
        ) {
          this.setView("修改检查井");
        } else {
          this.setView("修改设备");
        }
      } else if (this.selectedPipe.id) {
        this.setSelectedPipe({
          ...this.selectedPipe,
          _edit: true,
        });
        this.setView("修改管网");
      }

      this.controlX = -200;
      this.controlY = -200;
    },
    onDelete() {
      if (this.selectedWell.id) {
        let task = delEquip;
        if (this.selectedWell.type === "cutoffWell") {
          task = delInterceptingWell;
        } else if (this.selectedWell.type === "drain") {
          task = delOutlet;
        } else if (
          this.selectedWell.name === "污水检查井" ||
          this.selectedWell.name === "雨水检查井"
        ) {
          task = delWell;
        }
        const that = this;
        this.$confirm({
          title: `确认要删除${this.selectedWell.name}吗？`,
          onOk() {
            task({
              id: that.selectedWell.id,
              category: that.selectedWell.category,
              type: that.selectedWell.type,
            }).then(() => {
              if (that.selectedWell.type === "drain") {
                that.getRainOutletList();
              } else if (that.selectedWell.name === "污水检查井") {
                that.getSewageWellList();
              } else if (that.selectedWell.name === "雨水检查井") {
                that.getRainWellList();
              } else {
                that.getFacilityList();
              }

              that.setSelectedWell({});
              that.controlX = -200;
              that.controlY = -200;
            });
          },
        });
      } else if (this.selectedPipe.id) {
        const that = this;
        this.$confirm({
          title: `确认要删除管线？`,
          onOk() {
            delPipe({
              id: that.selectedPipe.id,
              category: that.selectedPipe.type,
            }).then(() => {
              if (that.selectedPipe.type === "sewage") {
                that.getSewagePipeList();
              } else {
                that.getRainPipeList();
              }

              that.setSelectedPipe({});
              that.controlX = -200;
              that.controlY = -200;
            });
          },
        });
      }
    },

    onMapClick(e) {
      console.log("onMapClick", e);
      const { lng, lat } = e.lnglat;
      this.setSelectedLnglat({
        lng,
        lat,
      });
    },

    initMap() {
      const { AMap } = window;
      if (!AMap) {
        return;
      }
      var map = new AMap.Map("map", {
        center: [119.056708, 33.583976], //中心点
        zoom: 14,
        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",
        plugin: ["AMap.MouseTool"],
      });

      map.on("zoomend", this.onZoomChange);

      return map;
    },

    onZoomChange() {
      const zoom = this.map?.getZoom() || 3;

      console.log("onZoomChange", zoom);

      this.sewagewellLayer?.size(zoom * 0.55);
      this.rainwellLayer?.size(zoom * 0.55);
      this.outletLayer?.size(zoom * 0.55);

      this.liquidLayer?.size(zoom * 0.55);
      this.flowLayer?.size(zoom * 0.55);
      this.rainGaugeLayer?.size(zoom * 0.55);
      this.videoLayer?.size(zoom * 0.55);
      this.waterQualityLayer?.size(zoom * 0.55);
      this.cutoLayer?.size(zoom * 0.55);

      this.facilityActiveLayer?.size(zoom * 0.6);

      this.getSewageWell();
      this.getrainwell();

      if (zoom > 18) {
        this.sewagePipeLayer?.size(3);
        this.rainpipeLayer?.size(3);
        this.activepipeLayer?.size(4);
      } else if (zoom > 14) {
        this.sewagePipeLayer?.size(2);
        this.rainpipeLayer?.size(2);
        this.activepipeLayer?.size(3);
      } else {
        this.sewagePipeLayer?.size(1);
        this.rainpipeLayer?.size(1);
        this.activepipeLayer?.size(2);
      }
    },
  },

  beforeDestroy() {
    this.map?.clearEvents();
    this.map?.off("zoomend", this.onZoomChange);
    this.map?.clearMap();
    this.map?.destroy();

    this.scene?.off("click", this.onMapClick);
    this.scene?.destroy();
  },
};
</script>
<style lang="less" scoped>
#map {
  position: fixed;
  top: 10vh;
  left: 4vw;
  right: 4vw;
  bottom: 4vh;
  z-index: 0;
}

.control-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.01);
}

#control {
  position: fixed;
  background-color: #f0f0f0;
  display: grid;
  grid-template-columns: 1fr;
  gap: 1px;
  z-index: 99;

  .select,
  .edit,
  .delete {
    background-color: #000;
    padding: 6px 12px;
    color: var(--theme-color);
    font-size: 13px;
    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .delete {
    color: red;
  }
}
</style>

