<template>
  <span>
    <a-button type="primary" @click="visible = true">导入</a-button>
    <a-modal title="导入文件" :visible="visible" :footer="null" @cancel="cancel">
      <a-form :form="form" @submit="handleSubmit">
        <a-form-item label="管道类型">
          <a-radio-group
            v-decorator="[
              'type',
              {
                rules: [
                  {
                    required: true,
                    message: '请选择',
                  },
                ],
              },
            ]"
          >
            <a-radio
              v-for="item in [{
                  name: '雨水',
                  value: 'rain',
                },
                {
                  name: '污水',
                  value: 'sewage',
                },]"
              :key="item.value"
              :value="item.value"
            >{{ item.name }}</a-radio>
          </a-radio-group>
        </a-form-item>

        <a-form-item label="文件">
          <input type="file" ref="upload" @change="onChange" style="display: none" />

          <a-button @click="upload">{{ file ? '重新上传' : '上传'}}</a-button>
        </a-form-item>

        <div class="right">
          <a-space>
            <a-button @click="cancel">取消</a-button>
            <a-button type="primary" html-type="submit" :loading="saveLoading">导入</a-button>
          </a-space>
        </div>
      </a-form>
    </a-modal>
  </span>
</template>
<script>
import { importFile } from "@/api/assets";
export default {
  props: {
    category: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      visible: false,
      form: this.$form.createForm(this),
      file: null,
      saveLoading: false,
    };
  },
  methods: {
    upload() {
      this.$refs.upload.click();
    },
    onChange(e) {
      const files = e.target.files;
      if (files.length === 0) {
        return;
      }
      this.file = files[0];
      e.target.value = null;
    },

    handleSubmit(e) {
      e.preventDefault();

      this.form.validateFields(async (err, values) => {
        if (!err) {
          const data = new FormData();
          data.append("category", this.category);
          data.append("type", values.type);
          data.append("file", this.file);
          this.saveLoading = true;
          importFile(data)
            .then((res) => {
              console.log("res", res);
              this.$emit("refresh");
              this.cancel();
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },
    cancel() {
      this.form.resetFields();
      this.file = null;
      this.visible = false;
    },
  },
};
</script>