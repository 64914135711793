<template>
  <div>
    <div style="cursor: pointer;margin-bottom: 16px" @click="back">
      <a-icon type="left" style="margin-right: 2px" />
      <span>返回</span>
    </div>

    <a-row>
      <a-col :span="12">
        分类：
        <DataDictFinder dictType="pipeType" :dictValue="record.category" iconType="text" />
      </a-col>
      <a-col :span="12">编号： {{ record.code }}</a-col>
    </a-row>

    <div style="padding-top: 16px"></div>

    <a-table bordered :data-source="list" :loading="loading" rowKey="id">
      <a-table-column title="任务单号" data-index="number" align="center"></a-table-column>

      <a-table-column title="维保类型" align="center">
        <template slot-scope="text">
          <DataDictFinder dictType="taskType" :dictValue="text.type" />
        </template>
      </a-table-column>

      <a-table-column title="任务创建日期" data-index="taskTime" align="center"></a-table-column>

      <a-table-column title="任务完成日期" data-index="finishTime" align="center"></a-table-column>

      <a-table-column title="执行人" data-index="executorName" align="center"></a-table-column>

      <a-table-column title="状态" align="center">
        <template slot-scope="text">
          <DataDictFinder
            dictType="taskStatus"
            :dictValue="text.status"
            iconType="badge"
            badgeType="color"
          />
        </template>
      </a-table-column>
    </a-table>
    <div v-if="list.length === 0" style="padding-bottom: 16px"></div>
  </div>
</template>
<script>
import { fetchRecordList } from "@/api/assets/index.js";

export default {
  props: {
    record: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      list: [],
      loading: false,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      let { id, category } = this.record;
      this.loading = true;
      fetchRecordList({
        deviceId: id,
        category,
        type: "outlet",
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res)) {
            this.list = res;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    back() {
      this.$emit("cancel");
    },
  },
};
</script>