import { LineLayer } from "@antv/l7";

export default {

  watch: {
    sewagePipeList: {
      handler() {
        this.getSewagePipe();
      },
      deep: true,
    }
  },

  methods: {

    onPipeRightClick(e) {
      console.log('pipe click', e)
      const { clientX, clientY } = e.target;
      this.setSelectedPipe(e.feature.properties)
      this.setSelectedWell({})
      this.controlX = clientX;
      this.controlY = clientY;
    },

    getSewagePipe() {
      if (this.selected.indexOf('污水管网') === -1) return;

      this.removeSewagePipe();

      let arr = [];
      for (let i = 0; i < this.sewagePipeList.length; i++) {
        const item = this.sewagePipeList[i];
        const startLnglat = [parseFloat(item[1]), parseFloat(item[2])];
        const endLnglat = [parseFloat(item[3]), parseFloat(item[4])];
        arr.push({
          type: "Feature",
          properties: {
            id: item[0],
            type: 'sewage',
            category: 'sewage',
            startLnglat,
            endLnglat,
          },
          geometry: {
            type: "LineString",
            coordinates: [startLnglat, endLnglat],
          },
        });
      }

      const sources = {
        type: "FeatureCollection",
        features: arr,
      };

      const sewagePipeLayer = new LineLayer({
        zIndex: 10,
      })
        .source(sources)
        .size(1)
        .shape("line")
        .color("#FFA500")
        .animate({
          interval: 1, // 间隔
          duration: 2, // 持续时间，延时
          trailLength: 2, // 流线长度
        });

      sewagePipeLayer.on("contextmenu", this.onPipeRightClick);
      this.sewagePipeLayer = sewagePipeLayer;
      this.scene?.addLayer(sewagePipeLayer);
    },


    removeSewagePipe() {
      if (this.sewagePipeLayer) {
        this.scene?.removeLayer(this.sewagePipeLayer);
        this.sewagePipeLayer = null;
      }
    },

    setPipeActive({ id, type, category, startLnglat, endLnglat, _change }) {

      this.closePipeActive();

      if (!id) return;

      const sources = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            properties: {
              id,
              type,
              category,
              startLnglat,
              endLnglat,
            },
            geometry: {
              type: "LineString",
              coordinates: [startLnglat, endLnglat],
            },
          },
        ],
      };

      const activepipeLayer = new LineLayer({
        zIndex: 20,
      })
        .source(sources)
        .size(2)
        .shape("line")
        .color("#ff0000")
        .animate({
          interval: 1, // 间隔
          duration: 2, // 持续时间，延时
          trailLength: 2, // 流线长度
        })

      this.activepipeLayer = activepipeLayer;

      this.activepipeLayer.on("click", this.closePipeActive);

      this.scene?.addLayer(activepipeLayer);

      if (_change) {
        this.scene?.setCenter(startLnglat, {
          padding: {
            right: 400,
          },
        });
      }

    },


    closePipeActive() {
      if (this.activepipeLayer) {
        this.scene?.removeLayer(this.activepipeLayer);
        this.activepipeLayer = null;
      }
    }

  },
};
