import request from '../request'

export function fetchList(params) {
    return request({
        url: '/model-analysis/pipe/cutoffwell/list',
        params
    })
}
export function fetchDetail(data) {
    return request({
        url: `/model-analysis/pipe/cutoffwell/query/${data.id}`,
        method:"post"
    })
}

export function add(data) {
    return request({
        url: `/model-analysis/pipe/cutoffwell/add`,
        method: 'post',
        data
    })
}

export function edit(data) {
    return request({
        url: '/model-analysis/pipe/cutoffwell/update',
        method: 'post',
        data
    })
}

export function del(data) {
    return request({
        url: '/model-analysis/pipe/cutoffwell/delete',
        method: 'post',
        data
    })
}