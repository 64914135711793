import { PointLayer } from "@antv/l7";

export default {

  watch: {
    rainWellList: {
      handler() {
        this.getrainwell();
      },
      deep: true,
    }
  },

  methods: {

     getrainwell() {
      if (this.selected.indexOf('雨水检查井') === -1) return;
      
      this.removerainwell();

      const zoom = this.map?.getZoom() || 3;

           if (zoom < 16.7) return;

      this.rainwellLayer = new PointLayer({
        zIndex: 100,
      }).source(this.rainWellList, {
          parser: {
            type: "json",
            x: "x",
            y: "y",
          },
        })
        .shape("rainwell")
        .size(zoom * 0.55)
 
      this.rainwellLayer.on("contextmenu", this.onFacilityRightClick);
      this.scene?.addLayer(this.rainwellLayer);
    },

    removerainwell() {
              if (this.rainwellLayer) {
                this.scene?.removeLayer(this.rainwellLayer);
                this.rainwellLayer = null;
            }
    },
  },
};
