import { LineLayer } from "@antv/l7";

export default {
  watch: {
    rainPipeList: {
      handler() {
        this.getrainpipe();
      },
      deep: true,
    }
  },

  methods: {
    getrainpipe() {

      if (this.selected.indexOf('雨水管网') === -1) return;

      this.removerainpipe();

      let arr = [];
      for (let i = 0; i < this.rainPipeList.length; i++) {
        const item = this.rainPipeList[i];

        const startLnglat = [parseFloat(item[1]), parseFloat(item[2])];
        const endLnglat = [parseFloat(item[3]), parseFloat(item[4])];

        arr.push({
          type: "Feature",
          properties: {
            id: item[0],
            type: 'rain',
            category: 'rain',
            startLnglat,
            endLnglat,
          },
          geometry: {
            type: "LineString",
            coordinates: [startLnglat, endLnglat],
          },
        });
      }

      const sources = {
        type: "FeatureCollection",
        features: arr,
      };

      const rainpipeLayer = new LineLayer({
        zIndex: 10,
      })
        .source(sources)
        .size(1)
        .shape("line")
        .color("#01C8E3")
        .animate({
          interval: 1, // 间隔
          duration: 2, // 持续时间，延时
          trailLength: 2, // 流线长度
        });

      rainpipeLayer.on("contextmenu", this.onPipeRightClick);
      this.rainpipeLayer = rainpipeLayer;
      this.scene?.addLayer(rainpipeLayer);
    },


    removerainpipe() {
      if (this.rainpipeLayer) {
        this.scene?.removeLayer(this.rainpipeLayer);
        this.rainpipeLayer = null;
      }
    },
  },
};
