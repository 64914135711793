<template>
  <div>
    <div style="cursor: pointer;margin-bottom: 16px" @click="back">
      <a-icon type="left" style="margin-right: 2px" />
      <span>返回</span>
    </div>

    <a-form
      :label-col="{ span: 8 }"
      :wrapper-col="{ span: 14 }"
      :colon="false"
      :form="form"
      @submit="handleSubmit"
    >
      <a-row>
        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="编号">
            <a-input
              v-decorator="[
                'code',
                {
                  rules: [{ required: true, message: '请输入' }],
                },
              ]"
            />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="管网类型">
            <a-select
              :disabled="view === '修改管网'"
              style="width: 100%"
              v-decorator="[
                'category',
                {
                  rules: [{ required: true, message: '请选择' }],
                },
              ]"
            >
              <a-select-option
                v-for="item in pipeTypeList"
                :key="item.value"
                :value="item.value"
              >{{ item.name }}</a-select-option>
            </a-select>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="起点位置">
            <a-input
              :disabled="true"
              v-decorator="['startLnglat',  {
                  rules: [{ required: true, message: '请输入' }],
                }]"
            >
              <span slot="suffix" @click="pasteLnglat('start')" style="cursor: pointer">
                <img src="@/assets/assets/location.png" class="location" />
              </span>
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="起点井">
            <a-input :disabled="true" v-decorator="['pointFrom']">
              <span slot="suffix" @click="pasteWell('start')" style="cursor: pointer">
                <img src="@/assets/assets/location.png" class="location" />
              </span>
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="终点位置">
            <a-input
              :disabled="true"
              v-decorator="['endLnglat',  {
                  rules: [{ required: true, message: '请输入' }],
                }]"
            >
              <span slot="suffix" @click="pasteLnglat('end')" style="cursor: pointer">
                <img src="@/assets/assets/location.png" class="location" />
              </span>
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="终点井">
            <a-input :disabled="true" v-decorator="['pointTo']">
              <span slot="suffix" @click="pasteWell('end')" style="cursor: pointer">
                <img src="@/assets/assets/location.png" class="location" />
              </span>
            </a-input>
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="起点高程(m)">
            <a-input-number style="width: 100%" v-decorator="['elevationFrom']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="终点高程(m)">
            <a-input-number style="width: 100%" v-decorator="['elevationTo']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="起点埋深(m)">
            <a-input-number style="width: 100%" v-decorator="['buringDepthFrom']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="终点埋深(m)">
            <a-input-number style="width: 100%" v-decorator="['buringDepthTo']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="管长(m)">
            <a-input-number style="width: 100%" v-decorator="['length']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="管径(mm)">
            <a-input-number style="width: 100%" v-decorator="['diameter']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="材质">
            <a-input v-decorator="['material']" />
          </a-form-item>
        </a-col>

        <a-col :lg="12" :md="12" :sm="24">
          <a-form-item label="所在道路">
            <a-input v-decorator="['location']" />
          </a-form-item>
        </a-col>
      </a-row>

      <div class="center">
        <a-space>
          <a-button @click="back">取消</a-button>
          <a-button type="primary" html-type="submit" :loading="saveLoading">保存</a-button>
          <a-button @click="transfer">管线转向</a-button>
        </a-space>
      </div>
    </a-form>
  </div>
</template>
<script>
import store from "../mixins/store";
import { mapGetters } from "vuex";

import { add, edit, fetchDetail } from "@/api/assets/pipe.js";
import { fetchDetail as fetchWellDetail } from "@/api/assets/inspectWell";

export default {
  mixins: [store],

  data() {
    return {
      detail: {},
      form: this.$form.createForm(this),
      saveLoading: false,
    };
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    pipeTypeList() {
      return this.findDataDict("pipeType");
    },
  },

  watch: {
    selectedPipe: {
      handler() {
        if (this.view === "修改管网" && this.selectedPipe._edit) {
          this.getDetail();
        }
      },
      deep: true,
    },
  },

  mounted() {
    if (this.view === "修改管网" && this.selectedPipe._edit) {
      this.getDetail();
    }
  },

  methods: {
    getDetail() {
      this.form.resetFields();
      this.detail = {};

      const hide = this.$message.loading("加载中...", 0);
      fetchDetail({
        id: this.selectedPipe.id,
        category: this.selectedPipe.type,
      })
        .then((res) => {
          console.log("pipe detail", res);
          if (!res) return;

          this.detail = res;

          this.form.setFieldsValue({
            code: res.code,
            category: res.category,
            buringDepthFrom: res.buringDepthFrom,
            buringDepthTo: res.buringDepthTo,
            diameter: res.diameter,
            elevationFrom: res.elevationFrom,
            elevationTo: res.elevationTo,
            length: res.length,
            location: res.location,
            material: res.material,
            startLnglat: res.xfrom + "," + res.yfrom,
            endLnglat: res.yto + "," + res.yto,
            pointFrom: res.pointFrom,
            pointTo: res.pointTo,
          });
        })
        .finally(() => {
          hide();
        });
    },
    pasteLnglat(type) {
      const { lng, lat } = this.selectedLnglat;
      if (!lng) {
        this.$message.error("请点击地图选择经纬度");
        return;
      }
      const value = lng + "," + lat;
      if (type === "start") {
        this.form.setFieldsValue({
          startLnglat: value,
        });
      } else {
        this.form.setFieldsValue({
          endLnglat: value,
        });
      }
    },

    pasteWell(type) {
      const { name, id, x, y } = this.selectedWell;

      if (name !== "污水检查井" && name !== "雨水检查井") {
        this.$message.error("请选择检查井");
        return;
      }

      const category = this.form.getFieldValue("category");

      if (!category) {
        this.$message.error("请选择管网类型");
        return;
      }

      if (name === "污水检查井" && category !== "sewage") {
        this.$message.error("请选择雨水检查井");
        return;
      }
      if (name === "雨水检查井" && category !== "rain") {
        this.$message.error("请选择污水检查井");
        return;
      }

      fetchWellDetail({
        category,
        id,
      }).then((res) => {
        const code = res ? res.code : "";

        if (type === "start") {
          this.form.setFieldsValue({
            pointFrom: code,
            startLnglat: x + "," + y,
          });
        } else {
          this.form.setFieldsValue({
            pointTo: code,
            endLnglat: x + "," + y,
          });
        }
      });
    },

    transfer() {
      const startLnglat = this.form.getFieldValue("startLnglat");
      const endLnglat = this.form.getFieldValue("endLnglat");
      const pointFrom = this.form.getFieldValue("pointFrom");
      const pointTo = this.form.getFieldValue("pointTo");

      this.form.setFieldsValue({
        startLnglat: endLnglat,
        endLnglat: startLnglat,
        pointFrom: pointTo,
        pointTo: pointFrom,
      });
    },

    handleSubmit(e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          const startArr = values.startLnglat.split(",");
          const endArr = values.endLnglat.split(",");
          const xfrom = parseFloat(startArr[0]);
          const yfrom = parseFloat(startArr[1]);
          const xto = parseFloat(endArr[0]);
          const yto = parseFloat(endArr[1]);

          let params = {
            id: this.detail.id,
            ...values,

            xfrom,
            yfrom,
            xto,
            yto,
          };
          this.saveLoading = true;

          let task = add;
          if (this.detail.id) {
            task = edit;
          }
          task(params)
            .then(() => {
              if (values.category === "sewage") {
                this.getSewagePipeList();
              } else {
                this.getRainPipeList();
              }
              this.back();
            })
            .finally(() => {
              this.saveLoading = false;
            });
        }
      });
    },

    back() {
      this.form.resetFields();

      this.setSelectedLnglat({});
      this.setSelectedWell({});
      this.setSelectedPipe({});

      this.setView("管网");
    },
  },
};
</script>

<style lang="less" scoped>
.location {
  display: block;
  height: auto;
  width: 12px;
}

.center {
  padding-bottom: 24px;
}
</style>